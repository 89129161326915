<template>
  <span :class="wrapperCssClass" v-if="authors && authors.length > 0">
    <span v-for="(author, index) in filteredAuthors" v-bind:key="author">
      <a
        rel="author"
        :href="href(author)"
        :class="'link ' + cssClass"
        v-html="author"
      ></a>
      <span v-if="showComma && index != filteredAuthors.length - 1">
        ,&nbsp;
      </span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    authors: Array,
    cssClass: {
      type: String,
      default: 'second',
    },
    wrapperCssClass: String,
    showComma: {
      type: Boolean,
      default: true,
    },
    activeLink: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    href(author) {
      if (this.activeLink) {
        return '/search/videos?query=' + encodeURI(author)
      } else {
        return null
      }
    },
  },
  computed: {
    filteredAuthors: function () {
      var self = this
      return self.authors.filter(function (item) {
        return item.length > 0
      })
    },
  },
}
</script>
