<template>
  <header class="header-main header-fixed">
    <div class="header-top">
      <div class="header-top-inner content-wrapper">
        <div class="header-top-left">
          <div class="logo-sam medium" style="background-color: inherit;">
            <!-- <link rel="canonical" /> -->
            <nuxt-link to="/" aria-label="Home">
              <img src="~/assets/img/kl-inafon-logo.svg" alt="KeepLearning" />
            </nuxt-link>
          </div>
          <a
            class="display-channels left"
            @click="toggleChannel()"
            aria-label="Chaînes"
          >
            <span class="icon small-scale icon-channels icon-neutral"></span>
            <span>Chaînes</span>
          </a>
        </div>
        <div class="header-top-center">
          <form class="search-container xxl" @submit.prevent="onSubmit">
            <input
              type="search"
              id="header-search-field"
              placeholder="Qu'est-ce qui vous intéresse ?"
              v-model="queryString"
              @input="isTyping = true"
            />
            <!-- v-on:keyup="search()" -->
            <button
              type="submit"
              v-bind:class="{ 'hidden-submit': hasQuery() }"
              class="icon-h-neutral"
            >
              <i class="icon icon-search">
                <span class="icon-accessibility">&nbsp;</span>
              </i>
            </button>
            <button
              empty-input
              v-show="hasQuery()"
              type="reset"
              class="icon-h-neutral search-container-cancel"
            >
              <i class="icon icon-cancel">
                <span class="icon-accessibility">&nbsp;</span>
              </i>
            </button>
          </form>
        </div>

        <div class="header-top-right">
          <div class="search-mobile">
            <a ui-sref="search.all" class="link second" href="/search/all">
              <i class="icon icon-search"></i>
              <span class="search-mobile--text">Search</span>
            </a>
          </div>
          <!-- <div class="profile-pic header-desktop">
            <figure>
              <img
                v-if="!$store.state.user"
                alt="My Channel"
                class="avatar medium"
                src="~/assets/img/avatarblank.jpg"
              />
              <img
                v-if="$store.state.user"
                alt="My Channel"
                class="avatar medium"
                :src="avatarUrl"
              />
            </figure>
          </div> -->
          <nuxt-link
            to="/my-channel/following"
            class="link second header-desktop username"
            v-if="$store.state.user"
          >
            {{ $store.state.user.first_name }}
          </nuxt-link>
          <button
            v-if="$store.state.user"
            class="button second medium header-desktop"
            @click="logout()"
            style="margin-top:10px;"
          >
            Déconnexion
          </button>

          <a
            v-else
            class="button second medium header-desktop"
            :href="loginUrl"
            aria-label="Login"
            style="margin-top:10px;"
          >
            Login
          </a>
          <div class="profile-mobile">
            <div class="profile-pic">
              <nuxt-link to="/my-channel/following" v-if="$store.state.user">
                <figure>
                  <img
                    src="~/assets/img/avatarblank.jpg"
                    class="avatar medium"
                  />
                </figure>
              </nuxt-link>
              <a :href="loginUrl" v-else>
                <figure>
                  <img
                    src="~/assets/img/avatarblank.jpg"
                    class="avatar medium"
                  />
                </figure>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HeaderSearchResults :query="queryString" :results="results" />
    <!-- <transition name="ng"> -->
    <HeaderChannelsNav :channelsNavOpened="channelsNavOpened" />
    <!-- </transition> -->
    <div class="messages-container"></div>
  </header>
</template>

<script>
import gql from 'graphql-tag'
import { Assets } from '~/lib/assets'

import HeaderSearchResults from '~/components/Layout/Header/HeaderSearchResults.vue'
import HeaderChannelsNav from '~/components/Layout/Header/HeaderChannelsNav.vue'

import SUMMARY_SEARCH_QUERY from '~/graphql/search/summary_search_query.gql'

import { debounce } from 'lodash'

export default {
  components: { HeaderSearchResults, HeaderChannelsNav },
  data() {
    return {
      loginUrl: process.env.loginUrl,
      queryString: '',
      channelsNavOpened: false,
      isTyping: false,
      results: {
        videos: [],
        videos_count: 0,
        chapters: [],
        chapters_count: 0,
        curations: [],
        curations_count: 0,
      },
    }
  },
  watch: {
    queryString: debounce(function () {
      this.isTyping = false
    }, 500),
    isTyping: function (value) {
      if (!value) {
        this.search(this.queryString)
      }
    },
  },
  methods: {
    toggleChannel() {
      this.channelsNavOpened = !this.channelsNavOpened
    },
    hasQuery() {
      return this.queryString.length > 0
    },
    async logout() {
      await this.$store.commit('set_user', null)
      await this.$apolloHelpers.onLogout()
    },
    onSubmit() {
      var _ = this
      this.$router.push(
        '/search/all?query=' + Object.assign(this.queryString),
        () => {
          _.queryString = ''
        }
      )
    },
    search() {
      var _ = this
      _.$apollo
        .query({
          query: SUMMARY_SEARCH_QUERY,
          variables: {
            query: this.queryString,
            limit_videos: 3,
            limit_chapters: 3,
            limit_curations: 3,
          },
        })
        .then(({ data, loading }) => {
          if (!loading) {
            _.results.chapters = data.search_chapters
            _.results.chapters_count =
              data.search_chapters_aggregate.aggregate.count

            _.results.curations = data.search_curations
            _.results.curations_count =
              data.search_curations_aggregate.aggregate.count

            _.results.videos = data.videos
            _.results.videos_count =
              data.search_videos_aggregate.aggregate.count
          }
        })
    },
  },
  computed: {
    avatarUrl() {
      return Assets.avatarUrl(this.$store.state.user)
    },
  },
}
</script>
