var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"player-sticky",attrs:{"ng-if":"!isMobile()"}},[_c('div',{staticClass:"player-sticky-timeline",on:{"click":function($event){return _vm.seekTo($event)}}},[_c('div',{staticClass:"player-sticky-timeline-progress",style:('width: ' + _vm.playerInfos.progress + '%;')})]),_vm._v(" "),_c('div',{staticClass:"player-sticky-inner"},[_c('div',{staticClass:"player-sticky-poster",staticStyle:{"background":"#f0f0f0"}},[(
          _vm.$store.getters['player/isVideoLoaded'] &&
            _vm.$store.state.player.state != 'PLAYING'
        )?_c('img',{attrs:{"src":_vm.$store.state.player.video.picture}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"player-controls-action player-controls-action-playPause",class:{ paused: _vm.isNotPlaying }},[_c('button',{staticClass:"action-button action-button-prev",attrs:{"type":"button"},on:{"click":function($event){return _vm.prevChapter()}}},[_c('i',{staticClass:"icon medium icon-control-next-prev"})]),_vm._v(" "),_c('button',{staticClass:"action-button action-button-play",attrs:{"type":"button"},on:{"click":function($event){return _vm.togglePlay()}}},[_c('i',{staticClass:"icon medium",class:{
            'icon-control-pause': _vm.isPlaying,
            'icon-control-play': _vm.isNotPlaying
          }})]),_vm._v(" "),_c('button',{staticClass:"action-button action-button-next",attrs:{"type":"button","disabled":_vm.$store.state.player.video.chapters.length ==
            _vm.currentChapter.index + 1},on:{"click":function($event){return _vm.nextChapter()}}},[_c('i',{staticClass:"icon medium icon-control-next-prev"})])]),_vm._v(" "),_c('div',{staticClass:"player-controls-info"},[_c('div',{staticClass:"player-controls-info-inner"},[_c('div',{staticClass:"player-controls-info-first"},[_c('span',{staticClass:"player-controls-info-time"},[_c('span',[_vm._v(_vm._s(_vm._f("toTime")(_vm.playerInfos.timecode || 0)))]),_vm._v("\n            /\n            "),_c('span',[_vm._v(_vm._s(_vm._f("toTime")(_vm.playerInfos.totalTime || 0)))])]),_vm._v(" "),_c('span',{staticClass:"player-controls-info-title"},[_c('nuxt-link',{staticClass:"link second",attrs:{"to":'/video/' + _vm.$store.state.player.video.slug}},[_vm._v("\n              "+_vm._s(_vm.$store.state.player.video.title)+"\n            ")])],1),_vm._v(" "),_c('span',{staticClass:"player-controls-info-authors"},[_c('Authors',{attrs:{"authors":_vm.$store.state.player.video.authors,"showComma":false}})],1)]),_vm._v(" "),_c('h4',{staticClass:"player-controls-info-text text-excerpt"},[(_vm.currentChapter.content)?_c('strong',[_vm._v("\n            Chapitre\n            "),_c('span',{attrs:{"ng-bind":"playerSticky.currentChapter.chapter_number"}},[_vm._v("\n              "+_vm._s(_vm.currentChapter.index + 1)+"\n            ")]),_vm._v("\n            -\n          ")]):_vm._e(),_vm._v(" "),(_vm.currentChapter.content)?_c('span',{staticClass:"lighter"},[_vm._v("\n            "+_vm._s(_vm.currentChapter.content)+"\n          ")]):_vm._e(),_vm._v(" "),(!_vm.currentChapter.content)?_c('span',{staticClass:"lighter"},[_vm._v("\n            Introduction\n          ")]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }