<template>
  <div
    class="popin-overlay"
    v-bind:class="{ in: $store.state.sharePopin.enabled }"
  >
    <a @click="closePopin()" class="popin-close-area"></a>
    <div class="popin-outer">
      <div class="popin popinShare popin--noPadding embed">
        <a class="popin-close" @click="closePopin()">
          <i class="icon icon-cancel"></i>
        </a>
        <div>
          <div class="popin-inner" v-if="$store.state.sharePopin.video">
            <p class="mgn-vs0 popin-title">
              <strong>Intégrez le lecteur SAM</strong>
              <br />
              <span class="text-light text-small popin-subtitle">
                Vous pouvez ajouter le lecteur à votre site, copiez-collez juste
                le HTML:
              </span>
            </p>

            <input
              type="text"
              select-input-click
              :value="embedIframeLink"
              class="input main popin-embedLink-input"
            />

            <div class="mgn-btm5">
              <p class="text-light text-small mgn-vs0">Intégrez:</p>
              <div class="radio">
                <input
                  name="embedPlayer-type"
                  type="radio"
                  id="embedPlayer-type-video"
                  v-model="embed.type"
                  value="video"
                />
                <label for="embedPlayer-type-video">Toute la video</label>
              </div>
              <div class="radio">
                <input
                  name="embedPlayer-type"
                  type="radio"
                  id="embedPlayer-type-chapter"
                  v-model="embed.type"
                  value="chapter"
                />
                <label for="embedPlayer-type-chapter">
                  Le chapitre numéro :
                </label>
                <input
                  select-input-click
                  v-model="embed.chapter"
                  class="input main"
                  type="number"
                  min="1"
                  :max="
                    $store.state.sharePopin.video.chapters_aggs.aggregate.count
                  "
                  style="
                    width: 40px;
                    display: initial;
                    margin: 0;
                    font-size: 14px;
                    padding: 2px;
                    text-align: center;
                  "
                />
              </div>
            </div>

            <div class="mgn-btm15">
              <p class="text-light text-small mgn-vs0">
                Disponible sans connexion:
              </p>
              <div class="radio">
                <input
                  type="radio"
                  id="embedPlayer-hidden-yes"
                  v-model="embed.hidden"
                  value="yes"
                  name="embedPlayer-hidden"
                />
                <label for="embedPlayer-hidden-yes">Oui</label>
              </div>

              <div class="radio">
                <input
                  type="radio"
                  id="embedPlayer-hidden-no"
                  v-model="embed.hidden"
                  value="no"
                  name="embedPlayer-hidden"
                  checked
                />
                <label for="embedPlayer-hidden-no">Non</label>
              </div>
            </div>

            <iframe
              :src="embedLink"
              width="483"
              height="375"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  data() {
    return {
      baseUrl: process.env.baseUrl,
      token: null,
      embed: {
        chapter: 1,
        hidden: 'no',
        type: 'video',
      },
    }
  },
  apollo: {
    generateEmbedToken: {
      query: gql`
        query($videoId: String!) {
          generateEmbedToken(videoId: $videoId) {
            accessToken
          }
        }
      `,
      manual: true,
      result({ data, loading }) {
        if (!loading) {
          this.token =
            data &&
            data.generateEmbedToken &&
            data.generateEmbedToken.accessToken &&
            data.generateEmbedToken.accessToken.token
        }
      },
      variables() {
        return {
          videoId: this.$store.state.sharePopin.video.id.toString(),
        }
      },
      skip() {
        return !this.$store.state.sharePopin.video
      },
    },
  },
  methods: {
    closePopin() {
      this.$store.commit('toggle_share_popin')
    },
  },
  computed: {
    addChapterLink() {
      if (this.embed.type == 'chapter') {
        return 'chapter=' + this.embed.chapter
      } else {
        return ''
      }
    },
    embedLink() {
      if (this.$store.state.sharePopin.video) {
        if (this.embed.hidden == 'yes') {
          if (this.addChapterLink != '') {
            return (
              this.baseUrl +
              '/embed/' +
              this.$store.state.sharePopin.video.slug +
              '?token=' +
              this.token +
              '&' +
              this.addChapterLink
            )
          } else {
            return (
              this.baseUrl +
              '/embed/' +
              this.$store.state.sharePopin.video.slug +
              '?token=' +
              this.token
            )
          }
        } else {
          return (
            this.baseUrl +
            '/embed/' +
            this.$store.state.sharePopin.video.slug +
            '?' +
            this.addChapterLink
          )
        }
      } else {
        return ''
      }
    },
    embedIframeLink() {
      return (
        '<iframe width="483" height="375" src="' +
        this.embedLink +
        '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>'
      )
    },
  },
}
</script>
