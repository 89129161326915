const baseUrl = `https://s3-eu-west-1.amazonaws.com/${process.env.awsBucket}`
export const Assets = {
  avatarUrl: user => {
    const path = user.avatar
      ? `uploads/user/avatar/${user.id}/thumb_my_channel_${user.avatar}`
      : 'assets/avatars/avatarblank.jpg'
    return `${baseUrl}/${path}`
  },
  backgroundUrl: name => {
    return `${baseUrl}/assets/backgrounds/${name}`
  },
  playlistCoverUrl: playlist => {
    return `${baseUrl}/uploads/playlist/cover/${playlist.id}/${playlist.cover}`
  },
  playlistThumbCoverUrl: playlist => {
    return `${baseUrl}/uploads/playlist/cover/${playlist.id}/thumb_home_${playlist.cover}`
  },
  channelCoverUrl: channel => {
    return `${baseUrl}/uploads/channel/cover/${channel.id}/cover_${channel.cover}`
  },
  channelThumbCoverUrl: channel => {
    return `${baseUrl}/uploads/channel/cover/${channel.id}/thumb_${channel.cover}`
  },
  channelBlurredCoverUrl: channel => {
    return `${baseUrl}/uploads/channel/cover/${channel.id}/blurred_cover_${channel.cover}`
  },
  channelSpecialHeaderLogoUrl: channel => {
    return `${baseUrl}/uploads/channel/special_header_logo/${channel.id}/${channel.special_header_logo}`
  },
  specialHomechannelLogoUrl: channel => {
    if (channel.special_homechannel_logo) {
      return `${baseUrl}/uploads/channel/special_homechannel_logo/${channel.id}/${channel.special_homechannel_logo}`
    }
  }
}
