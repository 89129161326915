<template>
  <div
    class="header-bottom animation-ngif-opacity animation-fast"
    v-if="showSearchResults"
  >
    <div
      class="row search-results-container content-wrapper"
      v-click-outside="hide"
    >
      <div class="one-third">
        <h4>
          <span>videos</span>
          ({{ results.videos_count }})
        </h4>
        <hr class="main" />
        <div class="search-no-results" v-if="results.videos.length <= 0">
          <div class="result-icon animation-enter-result">
            <i class="icon icon-no-result"></i>
            <i class="icon icon-no-result-over animation-pulse"></i>
          </div>
          <p>
            Hm ... il n'y a pas de
            <strong>vidéo</strong>
            correspondant à votre recherche.
          </p>
        </div>
        <ul class="complex-list" v-if="results.videos.length >= 0">
          <li v-for="video in results.videos" v-bind:key="video.id">
            <div>
              <div class="curation-image hover-zoom">
                <a class="full-link" :href="'/video/' + video.slug"></a>
                <img :alt="video.title" :src="video.picture" />
              </div>
              <div class="complex-list-content with-image">
                <h4 class="title">
                  <a class="link second" :href="'/video/' + video.slug">
                    {{ video.title }}
                  </a>
                </h4>
                <div class="complex-list-content-detail text-excerpt">
                  <span class="detail-year" v-if="video.archived_at">
                    {{
                      new Date(video.archived_at).toLocaleString('default', {year: 'numeric'})
                    }}
                  </span>
                  <span class="detail-year" v-if="!video.archived_at">
                  {{ new Date().getFullYear() }}
                  </span>
                  <Authors
                    :authors="video.authors"
                    wrapper-css-class="detail-author"
                  ></Authors>
                </div>

                <footer class="block-push-footer mgn-t10">
                  <div>
                    <span class="push-time">
                      {{ video.duration | toHumanTime }}
                    </span>
                    <span class="push-annotations">
                      <i class="icon small icon-annotate"></i>
                      <span>{{ video.curations_count }}</span>
                    </span>
                  </div>
                </footer>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="one-third">
        <h4>
          <span>Chapitres</span>
          ({{ results.chapters_count }})
        </h4>
        <hr class="main" />
        <!-- No Results -->
        <div class="search-no-results" v-if="results.chapters.length <= 0">
          <div class="result-icon animation-enter-result">
            <i class="icon icon-no-result"></i>
            <i class="icon icon-no-result-over animation-pulse"></i>
          </div>
          <p>
            Hm ... il n'y a pas de
            <strong>chapitre</strong>
            correspondant à votre recherche.
          </p>
        </div>
        <ul class="complex-list" v-if="results.chapters.length > 0">
          <li v-for="chapter in results.chapters" v-bind:key="chapter.id">
            <div>
              <div class="curation-image hover-zoom">
                <a
                  class="full-link"
                  :href="`/video/${chapter.video.slug}?curation=${chapter.timecode}`"
                ></a>
                <img :src="chapter.video.picture" />
                <div class="image-overlay black">
                  <div class="overlay-chapter">
                    <span class="chapter">
                      chap.&nbsp;{{ chapter.chapter_index + 1 }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="complex-list-content with-image">
                <h4 class="title">
                  <a
                    class="link second"
                    :href="`/video/${chapter.video.slug}?curation=${chapter.timecode}`"
                  >
                    {{ chapter.content }}
                  </a>
                </h4>
                <div class="complex-list-content-detail text-excerpt">
                  <span>in</span>
                  <a
                    class="link one-line second"
                    :href="`/video/${chapter.video.slug}`"
                  >
                    {{ chapter.video.title }}
                  </a>
                </div>

                <footer class="block-push-footer mgn-t10">
                  <div>
                    <span>at</span>
                    <span class="push-time">
                      {{ chapter.timecode | toHumanTime }}
                    </span>
                  </div>
                </footer>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="one-third">
        <h4>
          <span>curations</span>
          ({{ results.curations_count }})
        </h4>
        <hr class="main" />
        <div v-if="results.curations.length <= 0" class="search-no-results">
          <div class="result-icon animation-enter-result">
            <i class="icon icon-no-result"></i>
            <i class="icon icon-no-result-over animation-pulse"></i>
          </div>
          <p>
            Hm ... il n'y a pas
            <strong>d'annotations</strong>
            correspondant à votre recherche.
          </p>
        </div>
        <ul class="complex-list curations" v-if="results.curations.length > 0">
          <li v-for="curation in results.curations" v-bind:key="curation.id">
            <div>
              <div class="complex-list-content clearfix">
                <i
                  class="icon circle curations small icon-curation-link"
                  ng-class="iconify(curation.data_type)"
                ></i>
                <div class="title-wrapper">
                  <h4 class="title" truncate truncate-lines="2">
                    <a
                      class="link second"
                      :href="`/video/${curation.video.slug}?curation=${curation.timecode}`"
                    >
                      {{ curation.content }}
                    </a>
                  </h4>
                </div>
                <footer class="block-push-footer">
                  <span class="from-video" truncate truncate-lines="2">
                    <span class="in">
                      <span>in</span>
                    </span>
                    <a
                      class="link one-line main"
                      :href="`/video/${curation.video.slug}`"
                      v-shave="{ height: 20 }"
                    >
                      {{ curation.video.title }}
                    </a>
                  </span>
                </footer>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row content-wrapper button-centered">
      <nuxt-link
        :to="'/search/all?query=' + query"
        class="button large main mgn-btm40"
        @click="hide"
      >
        Afficher tous les résultats de recherche
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import Authors from '~/components/Shared/Authors'

export default {
  props: ['query', 'results'],
  components: { Authors },
  data() {
    return { videos: [], hidden: false }
  },
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his childrens
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    },
  },
  methods: {
    hide() {
      this.hidden = true
    },
  },
  computed: {
    showSearchResults() {
      return this.query.length > 0 && !this.hidden
    },
  },
}
</script>
