<template>
  <div class="player-sticky" ng-if="!isMobile()">
    <div class="player-sticky-timeline" @click="seekTo($event)">
      <div
        class="player-sticky-timeline-progress"
        :style="'width: ' + playerInfos.progress + '%;'"
      ></div>
    </div>
    <div class="player-sticky-inner">
      <div class="player-sticky-poster" style="    background: #f0f0f0;">
        <img
          :src="$store.state.player.video.picture"
          v-if="
            $store.getters['player/isVideoLoaded'] &&
              $store.state.player.state != 'PLAYING'
          "
        />
      </div>
      <div
        class="player-controls-action player-controls-action-playPause"
        v-bind:class="{ paused: isNotPlaying }"
      >
        <button
          type="button"
          class="action-button action-button-prev"
          @click="prevChapter()"
        >
          <i class="icon medium icon-control-next-prev"></i>
        </button>
        <button
          type="button"
          class="action-button action-button-play"
          @click="togglePlay()"
        >
          <i
            class="icon medium"
            v-bind:class="{
              'icon-control-pause': isPlaying,
              'icon-control-play': isNotPlaying
            }"
          ></i>
        </button>
        <button
          type="button"
          class="action-button action-button-next"
          @click="nextChapter()"
          :disabled="
            $store.state.player.video.chapters.length ==
              currentChapter.index + 1
          "
        >
          <i class="icon medium icon-control-next-prev"></i>
        </button>
      </div>
      <div class="player-controls-info">
        <div class="player-controls-info-inner">
          <div class="player-controls-info-first">
            <span class="player-controls-info-time">
              <span>{{ playerInfos.timecode || 0 | toTime }}</span>
              /
              <span>{{ playerInfos.totalTime || 0 | toTime }}</span>
            </span>
            <span class="player-controls-info-title">
              <nuxt-link
                :to="'/video/' + $store.state.player.video.slug"
                class="link second"
              >
                {{ $store.state.player.video.title }}
              </nuxt-link>
            </span>
            <span class="player-controls-info-authors">
              <Authors
                :authors="$store.state.player.video.authors"
                :showComma="false"
              />
            </span>
          </div>
          <h4 class="player-controls-info-text text-excerpt">
            <strong v-if="currentChapter.content">
              Chapitre
              <span ng-bind="playerSticky.currentChapter.chapter_number">
                {{ currentChapter.index + 1 }}
              </span>
              -
            </strong>
            <span class="lighter" v-if="currentChapter.content">
              {{ currentChapter.content }}
            </span>
            <span class="lighter" v-if="!currentChapter.content">
              Introduction
            </span>
          </h4>
        </div>
      </div>
      <!-- <div class="player-controls-action">
        <div class="player-controls-action">
          <div class="player-volume ng-hide" ng-show="playerVolume.show">
            <div class="player-volume-slider noUi-target noUi-rtl noUi-vertical noUi-background">
              <div class="noUi-base">
                <div class="noUi-origin noUi-connect" style="top: 0%;">
                  <div class="noUi-handle noUi-handle-upper"></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button class="action-button">
              <i
                class="icon icon-control-volume"
                ng-class="{'icon-control-volume': playerVolume.volume() > 0, 'icon-control-volume-off': playerVolume.volume() <= 0}"
              ></i>
              <span class="action-button-text">Volume</span>
            </button>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import Authors from '~/components/Shared/Authors'

export default {
  inject: ['playerInfos', 'player'],
  components: {
    Authors
  },

  computed: {
    currentChapter() {
      if (
        this.$store.state.player.currentChapter &&
        this.$store.state.player.currentChapter.content
      ) {
        return this.$store.state.player.currentChapter
      } else {
        return {}
      }
    },
    isPlaying() {
      return (
        this.$store.getters['player/isVideoLoaded'] &&
        this.$store.state.player.state == 'PLAYING'
      )
    },
    isNotPlaying() {
      return (
        this.$store.getters['player/isVideoLoaded'] &&
        this.$store.state.player.state != 'PLAYING'
      )
    }
  },
  methods: {
    async seekTo(event) {
      if (this.$store.state.player.video) {
        await this.player()
        this.player().seekTo(
          parseInt(
            (event.clientX / document.body.clientWidth) *
              this.$store.state.player.video.duration
          ),
          true
        )
      }
    },
    async nextChapter() {
      if (
        this.$store.state.player.video.chapters &&
        this.currentChapter &&
        this.currentChapter.index >= 0 &&
        this.$store.state.player.video.chapters.length >
          this.currentChapter.index + 1
      ) {
        await this.player().seekTo(
          this.$store.state.player.video.chapters[this.currentChapter.index + 1]
            .timecode,
          true
        )
        await this.player().playVideo()
      }
    },
    async prevChapter() {
      if (
        this.$store.state.player.video.chapters &&
        this.currentChapter.index &&
        this.currentChapter.index >= 0
      ) {
        await this.player().seekTo(
          this.$store.state.player.video.chapters[this.currentChapter.index]
            .timecode,
          true
        )
        await this.player().playVideo()
      }
    },
    async togglePlay() {
      await this.player()
      if (
        (await this.player().getPlayerState()) == 0 ||
        (await this.player().getPlayerState()) == 2
      ) {
        await this.player().playVideo()
      } else {
        await this.player().pauseVideo()
      }
    }
  }
}
</script>
