<template>
  <footer class="footer-main">
    <div class="footer-bottom">
      <div class="content-wrapper">
        <nav>
          <ul>
            <li v-if="showEditor">
              <a :href="editorUrl" class="button button-footer main medium">
                Éditeur
              </a>
            </li>
            <li>
              <a
                href="https://www.inafon.fr/credits-et-mentions-legales"
                target="_blank"
              >
                Confidentialité
              </a>
            </li>
          </ul>
        </nav>
        <div class="footer-bottom--copyright">
          Copyright © INAFON {{ new Date().getFullYear() }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      editorRoles: ['curator', 'director', 'admin'],
      editorUrlEnv: process.env.editorUrl,
    }
  },
  computed: {
    editorUrl() {
      return `${
        this.editorUrlEnv
      }/users/auth-jwt/callback?token=${this.$cookies.get('apollo-token')}`
    },
    showEditor() {
      return this.editorRoles.indexOf(this.userRole) >= 0
    },
    userRole() {
      return this.$store.state.user && this.$store.state.user.role
    },
  },
}
</script>
