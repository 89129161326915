<template>
  <div>
    <Header />
    <div class="appLayout">
      <client-only>
        <nuxt />
      </client-only>
      <Footer />
    </div>
    <div
      class="globalVideo-container"
      v-bind:class="classForPlayer()"
      v-show="
        $store.getters['player/isVideoLoaded'] &&
        $store.state.player.state == 'PLAYING'
      "
    >
      <div class="globalVideo">
        <div class="globalVideo-inner">
          <div class="globalVideo-catchClick"></div>
          <youtube
            ref="youtube"
            :video-id="videoId"
            :fitParent="true"
            :player-vars="playerVars"
            @playing="playing"
            @buffering="changeState"
            @cued="changeState"
            @paused="changeState"
            @ended="changeState"
            class="globalVideo-video"
          ></youtube>
        </div>
      </div>
    </div>
    <StickyPlayer v-if="showStickyPlayer" />
    <ShareEmbed />
  </div>
</template>

<script>
import Header from '~/components/Layout/Header.vue'
import Footer from '~/components/Layout/Footer.vue'
import StickyPlayer from '~/components/Video/Player/StickyPlayer.vue'
import ShareEmbed from '~/components/Video/Player/Overlay/ShareEmbed.vue'

export default {
  name: 'MainLayout',
  components: {
    Header,
    Footer,
    StickyPlayer,
    ShareEmbed,
  },
  data() {
    return {
      processId: null,
      videoId: '',
      lowerThan450: false,
      playerInfos: {
        progress: 0,
        timecode: null,
        totalTime: 0,
      },
      playerVars: {
        autoplay: 0,
        controls: 0,
        fs: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        rel: 0,
        showinfo: 0,
      },
    }
  },
  provide: function () {
    return {
      player: () => this.player,
      playerInfos: this.playerInfos,
    }
  },
  methods: {
    classForPlayer() {
      if (!this.showStickyPlayer && this.$route.path.match('^/video/')) {
        return 'top'
      } else {
        return 'bottom'
      }
    },
    setCurrentChapter(playerInfos) {
      var currentChapter = this.$store.state.player.video.chapters.find(
        function (chapter) {
          return (
            playerInfos.timecode >= chapter.timecode &&
            playerInfos.timecode <= chapter.timecode + chapter.duration
          )
        }
      )
      if (
        currentChapter &&
        (this.$store.state.player.currentChapter == null ||
          (this.$store.state.player.currentChapter &&
            currentChapter.id != this.$store.state.player.currentChapter.id))
      ) {
        this.$store.commit('player/setCurrentChapter', currentChapter)
      }
    },
    setCurrentCuration(playerInfos) {
      var currentCuration = this.$store.state.player.video.curations.find(
        function (curation) {
          return (
            Math.round(playerInfos.timecode) == Math.round(curation.timecode)
          )
        }
      )
      if (
        currentCuration &&
        (this.$store.state.player.currentCuration == null ||
          (this.$store.state.player.currentCuration &&
            currentCuration.id != this.$store.state.player.currentCuration.id))
      ) {
        this.$store.commit('player/setCurrentCuration', currentCuration)
      }
      if (
        this.$store.state.player.currentCuration &&
        this.$store.state.player.currentCuration.content.length * 0.05 <
          this.playerInfos.timecode -
            this.$store.state.player.currentCuration.timecode
      ) {
        this.$store.commit('player/setCurrentCuration', null)
      }
    },
    async playing($state) {
      this.$store.commit(
        'player/changeState',
        await this.player.getPlayerState()
      )

      this.playerInfos.totalTime = await this.player.getDuration()

      this.processId = setInterval(() => {
        this.player.getCurrentTime().then((time) => {
          let progress = (time / this.playerInfos.totalTime) * 100

          this.playerInfos.timecode = time
          this.playerInfos.progress = progress < 100 ? progress : 100

          var _ = this

          if (this.$store.state.player.video) {
            this.setCurrentChapter(this.playerInfos)
            this.setCurrentCuration(this.playerInfos)
          }
        })
      }, 250)
    },

    async changeState() {
      this.$store.commit(
        'player/changeState',
        await this.player.getPlayerState()
      )

      clearInterval(this.processId)
    },
    handleScroll(event) {
      if (window.scrollY > 450) {
        this.lowerThan450 = true
      } else {
        this.lowerThan450 = false
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    },
    isPlaying() {
      return this.$store.state.player.state == 'PLAYING'
    },
    showStickyPlayer() {
      var playerState = this.$store.state.player
      const regex = RegExp('^/video/')
      return (
        (!regex.test(this.$route.path) &&
          playerState.videoId.length > 0 &&
          this.$store.getters['player/isVideoLoaded']) ||
        (playerState.videoId.length > 0 &&
          playerState.videoId != playerState.pageVideoId &&
          regex.test(this.$route.path)) ||
        (this.lowerThan450 &&
          playerState.videoId.length > 0 &&
          playerState.videoId == playerState.pageVideoId &&
          regex.test(this.$route.path))
      )
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/css/main.scss';

.youtube-player {
  width: 100%;
  height: 100%;
}
.globalVideo-inner {
  height: 100%;
}
</style>
