<template>
  <div
    class="header-bottom header-bottom-channels animation-slidemenu"
    v-if="channelsNavOpened"
  >
    <div class="row channel-list-container content-wrapper">
      <div class="channel-list channel-list--sam">
        <h4 class="text-main">Chaînes thématiques</h4>
        <ul>
          <li v-for="channel in public_channels" v-bind:key="channel.slug">
            <nuxt-link :to="'/channel/' + channel.slug" class="logo-block">
              <span
                class="logo-sam small"
                :style="'background-color:#' + channel.color"
              >
                <img
                  src="~/assets/img/kl-logo-small.svg"
                  :alt="channel.title"
                />
              </span>
              <span class="channel-name">{{ channel.title }}</span>
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div
        class="channel-list channel-list--private"
        v-if="private_channels && private_channels.length > 0"
      >
        <h4 class="text-main">Chaines privées</h4>
        <ul>
          <li v-for="channel in private_channels" v-bind:key="channel.slug">
            <nuxt-link
              :to="'/channel/' + channel.slug"
              class="logo-block-special"
            >
              <img :alt="channel.title" :src="specialHeaderLogoUrl(channel)" />
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { Assets } from '~/lib/assets'

export default {
  // transition: 'ng',
  props: ['channelsNavOpened'],
  watch: {
    '$route.path': function () {
      this.$parent.channelsNavOpened = false
    },
  },
  data() {
    return {
      public_channels: [],
      private_channels: [],
    }
  },
  methods: {
    specialHeaderLogoUrl(channel) {
      return Assets.channelSpecialHeaderLogoUrl(channel)
    },
  },
  apollo: {
    channels: {
      query: gql`
        {
          public_channels: channels(where: { private: { _eq: false } }) {
            title
            slug
            color
          }
          private_channels: channels(where: { private: { _eq: true } }) {
            id
            title
            special_header_logo
            slug
          }
        }
      `,
      manual: true,
      result({ data, loading }) {
        if (!loading) {
          this.private_channels = data.private_channels
          this.public_channels = data.public_channels
        }
      },
    },
  },
}
</script>
